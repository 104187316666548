import React from 'react'
import '../styles/Footer.css'

export default function Footer() {
    return (
        <footer>
            <div className="footer">
                <p>Copyright &copy; 2024 C.S. Metalcraft Engineering</p>
            </div>
        </footer>
    )
}
